import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

const MARCOS = "barcode";
const ALBANY = "nominee";
const USERNAMES_DEV = [MARCOS, ALBANY];

const USERNAME_TO_TIMEZONE = {
  [MARCOS]: "America/New_York",
  [ALBANY]: "America/Los_Angeles",
};

const USERNAME_TO_TIMEZONE_SHORT = {
  [MARCOS]: "est",
  [ALBANY]: "pst",
};

const sanitizeSkynotes = (skynotes) => {
  const localDateToSkynotes = groupSkynotesByLocalDate(skynotes);
  return Object.entries(localDateToSkynotes)
    .map(([date, oneDaySkynotes]) => [
      date,
      requireBothUsernames(oneDaySkynotes),
    ])
    .sort((a, b) => b[0].localeCompare(a[0]));
};

const groupSkynotesByLocalDate = (skynotes) => {
  const localDateToSkynotes = {};
  skynotes.forEach((skynote) => {
    const localDateISO = getSkynoteLocalDateISO(skynote);
    if (localDateToSkynotes.hasOwnProperty(localDateISO)) {
      localDateToSkynotes[localDateISO].push(skynote);
    } else {
      localDateToSkynotes[localDateISO] = [skynote];
    }
  });
  return localDateToSkynotes;
};

const getSkynoteLocalDateISO = (skynote) => {
  const skynote_date_obj = new Date(skynote.timestamp_epoch);
  const zoned_date = utcToZonedTime(
    skynote_date_obj,
    USERNAME_TO_TIMEZONE[skynote.sender_username]
  );
  return format(zoned_date, "yyyy-MM-dd");
};

const requireBothUsernames = (oneDaySkynotes) => {
  return USERNAMES_DEV.map((username) =>
    oneDaySkynotes.find((skynote) => skynote.sender_username === username)
  );
};

export { sanitizeSkynotes, USERNAME_TO_TIMEZONE, USERNAME_TO_TIMEZONE_SHORT };
