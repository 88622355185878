import React from "react";
import Skynote from "./Skynote";
import styles from "../../css/SkynoteRow.module.css";

export default function SkynoteRow(props) {
  const localDateString = new Date(`${props.dateLocalIso}T00:00:00.000`)
    .toDateString()
    .toLowerCase();
  return (
    <div className={styles.rowContainer}>
      <div className={styles.dateContainer}>
        <div className={styles.dateText}>{localDateString}</div>
      </div>
      <div className={styles.skynoteRow}>
        <div className={styles.leftSkynoteContainer}>
          <Skynote skynote={props.skynotes[0]} />
        </div>
        <div className={styles.rightSkynoteContainer}>
          <Skynote skynote={props.skynotes[1]} />
        </div>
      </div>
    </div>
  );
}
