import { useEffect, useState } from "react";
import { sanitizeSkynotes } from "../../skynoteUtil";
import SkynoteRenderer from "./SkynoteRenderer";
import axios from "axios";
import LoadingScreen from "./LoadingScreen";

const BACKEND_BASE_URL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:8080";
const SKYNOTES_API = `${BACKEND_BASE_URL}/skynotes`;

function SkylogsApp(props) {
  const [skynotes, setSkynotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.get(SKYNOTES_API).then((response) => {
      setSkynotes(response.data);
      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <SkynoteRenderer skynotes={sanitizeSkynotes(skynotes)} />
  );
}

export default SkylogsApp;
