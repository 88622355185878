import React from "react";
import styles from "./../../css/LoadingScreen.module.css";

export default function LoadingScreen() {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loadingText}>~ loading ~</div>
    </div>
  );
}
