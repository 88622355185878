import React, { useCallback, useEffect, useState } from "react";
import styles from "../../css/Minesweeper.module.css";
import { combineClassNames } from "../../util";
import gameData from "../../data/board.json";
import { useNavigate } from "react-router-dom";

const ANSWER = [2, 2, 1, 1, 5, 4, 4];

const BOMB_ENCODING = 9;
const STARTING_POSITION = [14, 7];
const FLAG = "f";
const REVEAL = " ";
const RESET = "r";
const MOTION = {
  ArrowLeft: [-1, 0],
  ArrowRight: [1, 0],
  ArrowDown: [0, 1],
  ArrowUp: [0, -1],
};
const STARTING_RELEAVED_SQUARES = new Set([
  "4,10",
  "5,10",
  "6,10",
  "7,10",
  "8,10",
  "2,11",
  "3,11",
  "4,11",
  "5,11",
  "6,11",
  "7,11",
  "8,11",
  "2,12",
  "3,12",
  "4,12",
  "5,12",
  "6,12",
  "7,12",
  "8,12",
  "2,13",
  "3,13",
  "4,13",
  "5,13",
  "6,13",
]);

const DEBUG_ENDING = false;

const BOARD_HEIGHT = 16;
const BOARD_WIDTH = 30;
const TOTAL_SQUARES = BOARD_HEIGHT * BOARD_WIDTH;
const INITIAL_BOMBS = 95;

const INITIAL_MINUTES = 4;
const SECONDS_IN_MINUTE = 60;

const mod = (x, n) => ((x % n) + n) % n;

const GAME_STATES = {
  LOADING: 0,
  STARTED: 1,
  PLAYING: 2,
  LOST: 3,
  WON: 4,
};

const PUZZLE_STATES = {
  PUZZLE_INTRO: 0,
  MINESWEEPER: 1,
  MINESWEEPER_SOLVED: 2,
  PUZZLE_SOLVED: 3,
};

const stringToXY = (stringXY) => {
  const [x, y] = stringXY.split(",");
  return [parseInt(x), parseInt(y)];
};

const getSurroundingSquares = (x, y) => {
  return [
    [x - 1, y],
    [x - 1, y - 1],
    [x - 1, y + 1],
    [x, y - 1],
    [x, y + 1],
    [x + 1, y - 1],
    [x + 1, y],
    [x + 1, y + 1],
  ].filter(([x, y]) => x >= 0 && y >= 0 && x < BOARD_WIDTH && y < BOARD_HEIGHT);
};

const getSquare = (board, x, y) => {
  return board[y][x];
};

const revealedSquareAndExpand = (board, revealedSquares, startingPoint) => {
  const newRevealedSquares = new Set(revealedSquares);
  const toVisit = [startingPoint];
  while (toVisit.length > 0) {
    let [x, y] = toVisit.pop(0);
    newRevealedSquares.add([x, y].toString());
    if (getSquare(board, x, y) === 0) {
      getSurroundingSquares(x, y).forEach(([nextX, nextY]) => {
        const newSquareString = [nextX, nextY].toString();
        if (!newRevealedSquares.has(newSquareString)) {
          newRevealedSquares.add(newSquareString);
          toVisit.push(stringToXY(newSquareString));
        }
      });
    }
  }
  return newRevealedSquares;
};

const flagAll = (board) => {
  const newFlaggedSquares = new Set();
  for (let y = 0; y < BOARD_HEIGHT; y++) {
    for (let x = 0; x < BOARD_WIDTH; x++) {
      if (getSquare(board, x, y) === BOMB_ENCODING) {
        newFlaggedSquares.add(`${x},${y}`);
      }
    }
  }
  return newFlaggedSquares;
};

function ColorPicker(props) {
  const { selectedColor, setSelectedColorByIndex } = props;
  return (
    <div>
      {[0, 1, 2, 3, 4, 5, 6].map((i) => (
        <input
          key={i}
          value={selectedColor[i]}
          onChange={(e) => setSelectedColorByIndex(i, e.target.value)}
          maxLength="1"
          className={combineClassNames(
            styles.digitInput,
            (i === 2 || i === 5) && styles.extraSpace,
            i < 3 && styles.redInput,
            i >= 3 && i < 6 && styles.greenInput,
            i === 6 && styles.blueInput
          )}
        />
      ))}
    </div>
  );
}

function Square(props) {
  const { bombsNearby, isHidden, isSelected, isFlagged, isLost, isWon } = props;
  const isBomb = bombsNearby === BOMB_ENCODING;
  return (
    <th
      className={combineClassNames(
        isHidden
          ? isFlagged
            ? styles.flagged
            : styles.hidden
          : isBomb
          ? styles.revealedBomb
          : styles.revealedSquare,
        isSelected && styles.selected,
        isLost && styles.lost,
        isWon && styles.won
      )}
    >
      {isHidden
        ? isFlagged
          ? "◯"
          : ""
        : isBomb
        ? "*"
        : bombsNearby > 0
        ? bombsNearby
        : ""}
    </th>
  );
}

function Board(props) {
  const { board, revealedSquares, flaggedSquares, isLost, isWon } = props;
  const [selectedX, selectedY] = props.position;
  return (
    <table
      className={combineClassNames(
        styles.board,
        isWon && styles.won,
        isLost && styles.lost
      )}
    >
      <tbody>
        {board.map((row, y) => (
          <tr key={`row-${y}`}>
            {row.map((square, x) => (
              <Square
                bombsNearby={square}
                isHidden={!revealedSquares.has([x, y].toString())}
                isFlagged={flaggedSquares.has([x, y].toString())}
                isSelected={selectedX === x && selectedY === y}
                key={`x=${x}, y=${y}`}
                isLost={isLost}
                isWon={isWon}
              />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const formatTime = (timeInSeconds) => {
  const seconds = timeInSeconds % SECONDS_IN_MINUTE;
  const minutes = Math.floor(timeInSeconds / SECONDS_IN_MINUTE);
  return `${String(minutes).padStart(1, "0")}:${String(seconds).padStart(
    2,
    "0"
  )}`;
};

function TextScoller(props) {
  const [seenIndex, setSeenIndex] = useState(0);
  const finished = seenIndex === props.text.length - 1;
  const { moveOnToPuzzle } = props;

  const incrementSeenIndex = useCallback(
    (e) => {
      if (e.key === "Enter") {
        finished ? moveOnToPuzzle() : setSeenIndex(seenIndex + 1);
      }
    },
    [seenIndex, moveOnToPuzzle, finished]
  );

  useEffect(() => {
    window.addEventListener("keydown", incrementSeenIndex);
    return () => window.removeEventListener("keydown", incrementSeenIndex);
  }, [incrementSeenIndex]);

  return (
    <div className={styles.textScollerContainer}>
      {props.text.map(
        (para, i) =>
          seenIndex >= i && (
            <div className={styles.scrollTextParagraph} key={i}>
              {para}
            </div>
          )
      )}
      <div className={styles.scrollTextEnterPrompt}>
        ENTER to {finished ? "begin" : "continue"}
      </div>
    </div>
  );
}

function Letter(props) {
  const { closeSelf } = props;

  const closeLetter = useCallback(
    (e) => {
      if (e.key === "Enter") {
        closeSelf();
      }
    },
    [closeSelf]
  );

  useEffect(() => {
    window.addEventListener("keydown", closeLetter);
    return () => window.removeEventListener("keydown", closeLetter);
  }, [closeLetter]);

  return (
    <div className={styles.textScollerContainer}>
      <div className={styles.scrollTextParagraph}>
        <p>Dear Albany,</p>
        <p>
          Well done clearing all the mines- I knew you were the perfect person
          for the job. Don't celebrate too soon, though; those mines are still
          active.
        </p>
        <p>
          In order to defuse them, you'll need to guess the exact color I'm
          thinking of. Luckily, I know you're very good at puzzles; you could
          solve this one with your eyes closed. Just don't expect everything to
          fall in line, if you know what I mean.
        </p>
        <p>Good luck!</p>
        <p>-h</p>
      </div>
      <div className={styles.scrollTextEnterPrompt}>
        ENTER to return to puzzle
      </div>
    </div>
  );
}

export default function Minesweeper() {
  const [currentPosition, setCurrentPosition] = useState(STARTING_POSITION);
  const [flaggedSquares, setFlaggedSquares] = useState(new Set());
  const [revealedSquares, setRevealedSquares] = useState(
    STARTING_RELEAVED_SQUARES
  );
  const [gameState, setGameState] = useState(GAME_STATES.LOADING);
  const [selectedColor, setSelectedColor] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [secondsLeft, setSecondsLeft] = useState(
    INITIAL_MINUTES * SECONDS_IN_MINUTE
  );
  const [timerIntervalId, setTimerIntervalId] = useState(0);
  const [puzzleState, setPuzzleState] = useState(PUZZLE_STATES.PUZZLE_INTRO);
  const [showingLetter, setShowingLetter] = useState(false);
  const navigate = useNavigate();

  const red = parseInt(selectedColor.slice(0, 3).join(""));
  const green = parseInt(selectedColor.slice(3, 6).join(""));
  const blue = parseInt(selectedColor[6]);

  const board = gameData["board"];
  const firstText = gameData["firstText"];

  const squaresLeftToReveal =
    TOTAL_SQUARES - INITIAL_BOMBS - revealedSquares.size;

  const handleTime = useCallback(() => {
    if (
      gameState === GAME_STATES.PLAYING &&
      puzzleState === PUZZLE_STATES.MINESWEEPER
    ) {
      setSecondsLeft(secondsLeft - 1);
    }
    if (secondsLeft - 1 === 0) {
      setGameState(GAME_STATES.LOST);
    }
  }, [secondsLeft, gameState, puzzleState]);

  useEffect(() => {
    const handleTimeInterval = window.setInterval(handleTime, 1000);
    setTimerIntervalId(handleTimeInterval);
    return () => window.clearInterval(handleTimeInterval);
  }, [handleTime]);

  useEffect(() => {
    if (squaresLeftToReveal === 0 && gameState === GAME_STATES.PLAYING) {
      setGameState(GAME_STATES.WON);
      setPuzzleState(PUZZLE_STATES.MINESWEEPER_SOLVED);
      setFlaggedSquares(flagAll(board));
    }
  }, [gameState, squaresLeftToReveal, board, timerIntervalId]);

  useEffect(() => {
    if (DEBUG_ENDING) {
      const newRevealedSquares = new Set();
      if (board) {
        for (let y = 0; y < BOARD_HEIGHT; y++) {
          for (let x = 0; x < BOARD_WIDTH; x++) {
            if (getSquare(board, x, y) !== BOMB_ENCODING) {
              newRevealedSquares.add(`${x},${y}`);
            }
          }
        }
      }
      newRevealedSquares.delete("0,0");
      newRevealedSquares.delete("0,1");
      setRevealedSquares(newRevealedSquares);
    }
  }, [board, setRevealedSquares]);

  const move = useCallback(
    (e) => {
      const { key } = e;
      if (MOTION.hasOwnProperty(key)) {
        const [currentX, currentY] = currentPosition;
        const [deltaX, deltaY] = MOTION[key];
        setCurrentPosition([
          mod(currentX + deltaX, BOARD_WIDTH),
          mod(currentY + deltaY, BOARD_HEIGHT),
        ]);
      } else if (key === REVEAL && gameState !== GAME_STATES.LOST) {
        const currentPositionString = currentPosition.toString();
        if (!flaggedSquares.has(currentPositionString)) {
          const newRevealedSquares = revealedSquareAndExpand(
            board,
            revealedSquares,
            currentPosition
          );
          setRevealedSquares(newRevealedSquares);
          if (getSquare(board, ...currentPosition) === BOMB_ENCODING) {
            setGameState(GAME_STATES.LOST);
          }
        }
      } else if (
        key === FLAG &&
        gameState === GAME_STATES.PLAYING &&
        !revealedSquares.has(currentPosition.toString())
      ) {
        const currentPositionString = currentPosition.toString();
        const newFlagged = new Set(flaggedSquares);
        if (newFlagged.has(currentPositionString)) {
          newFlagged.delete(currentPositionString);
        } else {
          newFlagged.add(currentPositionString);
        }
        setFlaggedSquares(newFlagged);
      } else if (key === RESET) {
        setFlaggedSquares(new Set());
        setRevealedSquares(STARTING_RELEAVED_SQUARES);
        setGameState(GAME_STATES.PLAYING);
        setSecondsLeft(INITIAL_MINUTES * SECONDS_IN_MINUTE);
      }
    },
    [currentPosition, revealedSquares, flaggedSquares, board, gameState]
  );

  const setSelectedColorByIndex = (i, value) => {
    setSelectedColor(
      selectedColor.map((this_value, this_i) =>
        this_i === i ? value : this_value
      )
    );
  };

  useEffect(() => {
    window.addEventListener("keydown", move);
    return () => window.removeEventListener("keydown", move);
  }, [move]);

  return puzzleState === PUZZLE_STATES.PUZZLE_INTRO ? (
    <TextScoller
      text={firstText}
      moveOnToPuzzle={() => {
        setGameState(GAME_STATES.PLAYING);
        setPuzzleState(PUZZLE_STATES.MINESWEEPER);
      }}
    />
  ) : showingLetter ? (
    <Letter closeSelf={() => setShowingLetter(false)} />
  ) : (
    <div className={styles.gameContainer}>
      {puzzleState >= PUZZLE_STATES.MINESWEEPER_SOLVED && (
        <button
          className={styles.postMinesweeperLetter}
          onClick={() => setShowingLetter(true)}
        >
          <span
            className={combineClassNames(
              "material-symbols-outlined",
              styles.emailSymbol
            )}
          >
            mark_email_unread
          </span>
        </button>
      )}
      {puzzleState === PUZZLE_STATES.PUZZLE_SOLVED && (
        <button
          className={combineClassNames(
            styles.postMinesweeperLetter,
            styles.finalLetter
          )}
          onClick={() => navigate("/yay")}
        >
          <span
            className={combineClassNames(
              "material-symbols-outlined",
              styles.emailSymbol
            )}
          >
            mark_email_unread
          </span>
        </button>
      )}
      <div
        className={combineClassNames(
          styles.boardContainer,
          puzzleState === PUZZLE_STATES.PUZZLE_SOLVED && styles.won
        )}
      >
        <div
          className={combineClassNames(
            styles.timer,
            secondsLeft <= 30 && gameState !== GAME_STATES.LOST && styles.red,
            gameState === GAME_STATES.LOST && styles.boom,
            gameState === GAME_STATES.WON && styles.green
          )}
        >
          {formatTime(secondsLeft)}
        </div>
        {gameState === GAME_STATES.LOST && (
          <>
            <div className={styles.boomTextBack}>BOOM!</div>
            <div className={styles.boomTextFront}>BOOM!</div>
          </>
        )}
        <Board
          board={board}
          position={currentPosition}
          revealedSquares={revealedSquares}
          flaggedSquares={flaggedSquares}
          isLost={gameState === GAME_STATES.LOST}
          isWon={gameState === GAME_STATES.WON}
        />
        <div className={styles.bombsRemainingText}>
          {INITIAL_BOMBS - flaggedSquares.size} bombs remaining
        </div>
        <ColorPicker
          selectedColor={selectedColor}
          setSelectedColorByIndex={setSelectedColorByIndex}
        />
        <div className={styles.colorPreviewAndCheckButton}>
          <div
            className={styles.colorPreview}
            style={{ backgroundColor: `rgb(${red},${green},${blue})` }}
          />
          <button
            className={styles.checkButton}
            onClick={() =>
              selectedColor.toString() === ANSWER.toString() &&
              setPuzzleState(PUZZLE_STATES.PUZZLE_SOLVED)
            }
          >
            defuse
          </button>
        </div>
      </div>
    </div>
  );
}
