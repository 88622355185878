import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import SkylogsApp from "./components/skylogs/SkylogsApp";
import Minesweeper from "./components/albany21/Minesweeper";
import PuzzleSolvedScreen from "./components/albany21/PuzzleSolvedScreen";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SkylogsApp />} />
        <Route path="/albs-21" element={<Minesweeper />} />
        <Route path="/yay" element={<PuzzleSolvedScreen />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
