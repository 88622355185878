import styles from "./../../css/Minesweeper.module.css";

export default function PuzzleSolvedScreen() {
  return (
    <div className={styles.finalMessageContainer}>
      <div>
        <p>Dearest Albany,</p>
        <p>
          Congratulations! You defused the landmines and saved Berkeley from
          utter oblivion. I knew your minesweeper talent, jazz ear, and
          puzzle-solving prowess would make you the perfect person to foil my
          scheme.
        </p>
        <p>
          I have a confession to make, however. The truth is, I devised this
          entire plan just to get your attention. I've admired you for a long
          time but didn't know how to get you to notice me without threatening
          the demolition of an entire city. Yes, I may have gone a bit too bar
          but, as they say, all's fair in love and war.
        </p>
        <p>
          You may not know this, but I'm something of a musician myself. In a
          moment of (hot) brooding, I wrote something for you that I hope you'll
          enjoy. Please make sure my producer prefaces this with a few words on
          its slightly rushed production. It's a love song and it's cringe but
          happy birthday, Albany.
        </p>
        <p>-h</p>
      </div>
      <div>
        <a
          href="https://drive.google.com/file/d/10yz3P-_7GwElyhtb5i44098YqBLZMa_R/view?usp=sharing"
          target="blank"
        >
          <button className={styles.playAudioButton}>listen</button>
        </a>
      </div>
      <div className={styles.lyrics}>
        <p>lyrics</p>
        <p>
          You could say that I fell for you
          <br />
          But I fell and took you with me
          <br />
          It's a matter of perspective
        </p>
        <p>
          It's a blow to the back of the head
          <br />
          That fills my* vision with starlight
          <br />
          That makes my* neck turn red
        </p>
        <p>
          That makes me want to shatter
          <br />
          Under your weight
        </p>
        <p>
          A recurring nightmare
          <br />
          And intead of me it was you whose blood-stained collar
          <br />
          We wrung out
        </p>
        <p>
          It would break my heart to have damaged
          <br />
          That beautiful machine between your eyes
        </p>
        <p>
          That I would watch run circles
          <br />
          Around mine
        </p>
        <p>
          That cares for unknown matters
          <br />
          That sprints across the minefield
          <br />
          That gives me butterflies
          <br />
          And trauma
        </p>
        <p>
          I'll watch when you're rotating vectors in the air
          <br />
          I'll lose when you play Palutena
          <br />
          I'll laugh cause you characterize dogs better than people
          <br />
          And I'll die when I lift you up
        </p>
        <p>
          It's only
          <br />
          A fatal
          <br />
          Attraction
          <br />
          To you
        </p>
        <p>* Sung incorrectly, didn't notice until later</p>
      </div>
    </div>
  );
}
