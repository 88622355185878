import React from "react";
import styles from "./../../css/Skynote.module.css";
import { combineClassNames } from "../../util";
import { utcToZonedTime } from "date-fns-tz";
import {
  USERNAME_TO_TIMEZONE,
  USERNAME_TO_TIMEZONE_SHORT,
} from "../../skynoteUtil";
import { format } from "date-fns";

const getTimeStringForPerson = (timestamp_epoch, username) => {
  const localized_date_obj = utcToZonedTime(
    timestamp_epoch,
    USERNAME_TO_TIMEZONE[username]
  );
  return format(localized_date_obj, "h:mm aaa");
};

export default function Skynote(props) {
  const hasImage = Boolean(props.skynote);
  return (
    <div className={styles.skynoteContainer}>
      <div className={styles.skynoteImageContainer}>
        <img
          src={
            hasImage
              ? props.skynote.photo_url
              : "https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/HD_transparent_picture.png/1200px-HD_transparent_picture.png"
          }
          alt=""
          className={combineClassNames(
            styles.skynoteImage,
            !hasImage && styles.noImage
          )}
        />
      </div>
      <div className={styles.skynoteTextContainer}>
        <div className={styles.skynoteText}>
          {hasImage ? (
            props.skynote.notes
          ) : (
            <div className={styles.noImageText}>
              (No picture&mdash; busy day today.)
            </div>
          )}
          {hasImage && (
            <div className={styles.byLine}>
              ~ {props.skynote.sender_username},{" "}
              {getTimeStringForPerson(
                props.skynote.timestamp_epoch,
                props.skynote.sender_username
              )}{" "}
              {USERNAME_TO_TIMEZONE_SHORT[props.skynote.sender_username]}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
