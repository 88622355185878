import React from "react";
import SkynoteRow from "./SkynoteRow";
import styles from "./../../css/SkynoteRenderer.module.css";

export default function SkynoteRenderer(props) {
  return (
    <div className={styles.skynoteBody}>
      <div className={styles.allSkynotesContainer}>
        {props.skynotes.map(([dateLocalIso, skynotes]) => (
          <SkynoteRow
            skynotes={skynotes}
            dateLocalIso={dateLocalIso}
            key={dateLocalIso}
          />
        ))}
      </div>
    </div>
  );
}
